import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'; // import useNavigate if using React Router v6
import PoolDeadlineTimer from "./PoolDeadlineTimer";
import { formatToDollar } from "./CriticalFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faL, faStar } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import CustomModal from './CustomModal';
import { apiP } from './CriticalFunctions';
import UserPreviewCard from './UserPreviewCard';
import ManagePool from './ManagePool';
import Loading from './Loading';
import './Pool.css';
import { useAuth } from './AuthContext';
import { useErrorBoundary } from "react-error-boundary";
import { StyledButton, StyledCard, StyledListGroup, StyledH5, StyledH4, StyledParagraph } from './StyledBootstrap';
import { pastDeadline } from './CriticalFunctions';
import { myAxiosInstance } from './axiosConfig';
import { StyledH3 } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { stylePoolSlug, convertPackageToGreek } from './CriticalFunctions';
import { useMediaQuery } from 'react-responsive';
import PoolRecapTutorial from './PoolRecapTutorial';
import SigningRecap from './SigningRecap';
import AddUserToPool from './AddUserToPool';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';


const FullScreenYouTubePopup = ({ onClose, videoId }) => {
    const iframeRef = useRef(null);
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  
    useEffect(() => {
      let player;
  
      const handleVideoEnd = (event) => {
        if (event.data === window.YT.PlayerState.ENDED) {
          onClose();
        }
      };
  
      const initializePlayer = () => {
        if (iframeRef.current) {
          player = new window.YT.Player(iframeRef.current, {
            videoId: videoId,
            playerVars: {
              autoplay: 1,
              controls: 1,
              rel: 0,
              modestbranding: 1,
              iv_load_policy: 3,
              mute: 1,
            },
            events: {
              onStateChange: handleVideoEnd,
            },
          });
        }
      };
  
      if (window.YT && window.YT.Player) {
        initializePlayer();
      } else {
        window.onYouTubeIframeAPIReady = initializePlayer;
        const script = document.createElement("script");
        script.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(script);
      }
  
      return () => {
        if (player) player.destroy();
      };
    }, [videoId, onClose]);
  
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent overlay
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        {/* Modal Container */}
        <div
          style={{
            position: "relative",
            width: isMdOrLarger ? "65vw" : "95vw", // Responsive modal width
            backgroundColor: "white", // White modal background
            borderRadius: "12px", // Rounded corners
            padding: isMdOrLarger ? "0.6rem 2rem 2rem 2rem" : "0.2rem 0.5rem 0.5rem 0.5rem", // Padding around the video container
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow for depth
            display: "flex",
            flexDirection: "column", // Stack close button and video vertically
          }}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            style={{
              alignSelf: "flex-end", // Align button to the top-right corner
              marginBottom: isMdOrLarger ? "0.5rem" : "0", // Add space between button and video
              color: "black", // Black text
              background: "transparent", // Transparent background
              border: "none", // No border
              cursor: "pointer",
              fontSize: "1.5rem", // Font size for ✖
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "gray";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "black";
            }}
          >
            ✖
          </button>
  
          {/* Video Container */}
          <div
            style={{
              width: "100%", // Take full width of the modal
              aspectRatio: "16 / 9", // Maintain 16:9 aspect ratio
              backgroundColor: "black", // Fallback background for loading
              borderRadius: "8px", // Slight rounding for video corners
              overflow: "hidden", // Prevent overflow
            }}
          >
            <div
              ref={iframeRef}
              style={{
                width: "100%",
                height: "100%",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  };


//change invitee look
//weird total spots going down error

function PoolPage() {
    const { slug } = useParams();
    const [reload, setReload] = useState(1);
    const [userInfo, setUserInfo] = useState(null);
    const [publicDetails, setPublicDetails] = useState(null);
    const [privateDetails, setPrivateDetails] = useState(null);
    const [extraPrivateDetails, setExtraPrivateDetails] = useState(null);
    const [captainInfo, setCaptainInfo] = useState(null);
    const [otherUsers, setOtherUsers] = useState([]);
    const [otherNonUsers, setOtherNonUsers] = useState([]);
    const [isBottomLoading, setIsBottomLoading] = useState(true);
    const [poolMessageLoading, setPoolMessageLoading] = useState(false);

    const themeMode = useTheme();

    const location = useLocation();

    const [editPoolData, setEditPoolData] = useState(null);
    const [delPool, setDelPool] = useState(false);
    const [removeInvitees, setRemoveInvitees] = useState([]);
    const [addInvitees, setAddInvitees] = useState([]);
    const [inviteEmails, setInviteEmails] = useState([]);

    const [modalShow, setModalShow] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [manageReload, setManageReload] = useState(false);



    const [showTutorial, setShowTutorial] = useState(false); // To show or hide the tutorial modal
    const [tutorialPart, setTutorialPart] = useState(1); // To track the current part of the tutorial


    const [showRecap, setShowRecap] = useState(false); // To show or hide the tutorial modal
    const [recapPart, setRecapPart] = useState(1); // To track the current part of the tutorial


    const [showAddModal, setShowAddModal] = useState(false); // To show or hide the add modal 


    const [showIframeModal, setShowIframeModal] = useState(false);


    const handleNextRecap = () => {
        if (recapPart < 1) {
            setRecapPart(recapPart + 1);
        } else {
            setShowRecap(false); // Hide the tutorial after the last part
            //markTutorialCompleted();
            setModalShow(true);

        }
    };


    const handleBackRecap = () => {
        if (recapPart > 1) {
            setRecapPart(recapPart - 1);
        }
    };

    const handleSkipRecap = () => {
        setShowRecap(false); // Hide the tutorial
    };


    const handleNextTutorial = () => {
        if (tutorialPart < 1) {
            setTutorialPart(tutorialPart + 1);
        } else {
            setShowTutorial(false); // Hide the tutorial after the last part
            //markTutorialCompleted();

        }
    };


    const handleBackTutorial = () => {
        if (tutorialPart > 1) {
            setTutorialPart(tutorialPart - 1);
        }
    };

    const handleSkipTutorial = () => {
        setShowTutorial(false); // Hide the tutorial
        //markTutorialCompleted();
    };

    const [signingInterface, setSigningInterface] = useState(null);

    const profilePictureStyle = {
        width: '5rem', // Adjust width for less height
        height: '5rem', // Adjust height for less height
        borderRadius: '50%',
        marginRight: '0.7rem'
    };



    const handlePoolChatButtonClick = async () => {
        try {
            console.log(slug);
            setPoolMessageLoading(true);
            setActiveChannel(slug);
            setChatVisible(true);

        }
        catch (error) {
            console.error('Error setting up channel', error.response.data);

        }
        finally {
            setPoolMessageLoading(false);
        }



    };

    
    const handlePoolAddButtonClick = async () => {
        setShowAddModal(true);
    };

    const handleCloseAddButton = async () => {
        setShowAddModal(false);
    };

    const handleSaveInvites = async () => {
        setIsBottomLoading(true);
        alert('yay');
        // api
        // reload
        setReload(reload+1);
        setIsBottomLoading(false);
    }
    const poolMembers = useRef([{
        company: '',
        email: '',
        first_name: '',
        graduation_date: '',
        is_verified: '',
        last_name: '',
        major_or_grad_program: '',
        pooled: '',
        portfolio_count: '',
        profile_picture: '',
        slug: '',
        university: ''}]);

    const bottomRef = useRef(null);
    const [triggerRender, setTriggerRender] = useState(0);


    const { showBoundary } = useErrorBoundary();

    const { accountStage, setActiveChannel, setChatVisible } = useAuth();

    const navigate = useNavigate();

    const buttonOptions = [
        { buttonText: 'Accept Invite', action: async () => { apiPoolAction('accept-pool-invite', "post", null, true) }, title: 'Accept Invite', modalButtonText: 'Accept Invite', modalText: 'Please confirm you would like to accept the invite to this pool.' },
        { buttonText: 'Invite Accepted', action: async () => { apiPoolAction('leave-pool', "post", null, true) }, title: 'Invite Accepted', modalButtonText: 'Leave', modalText: 'You have accepted the invite to this pool. Only click "Leave" below if you are sure you would like to leave the pool.' },
        { buttonText: 'Join Pool', action: () => signExternally(), title: 'Review and join the pool...', modalButtonText: 'View Externally', modalText: '' },
        { buttonText: 'Manage Pool', action: async () => { handleManagePoolModalButtonClick() }, title: 'Manage Pool', modalButtonText: 'Save Changes to Pool', modalText: 'Please review your changes carefully before saving them.' },
        { buttonText: 'View Agreement', action: () => signExternally(), title: 'View Signed Agreement', modalButtonText: 'View Agreement', modalText: 'Here is the signed agreement.' },
        { buttonText: 'Verify Account', action: () => navigate("/dashboard/settings"), title: 'Verify Account Before Signing', modalButtonText: 'Go to Settings', modalText: 'Please verify your identity before signing the contract.' },
        {
            buttonText: 'Request to Join',
            action: () => {
                // Assuming captainInfo contains the email address of the captain
                const captainEmail = captainInfo ? captainInfo.email : "defaultEmail@example.com";
                const captainName = captainInfo ? captainInfo.first_name : "Captain";
                const subject = encodeURIComponent("Request to Join Pool ");
                const body = encodeURIComponent("Dear " + captainName + ",\n\nI would like to request to join your pool. Please let me know if there's any further information you need from me.\n\nBest regards,");
                window.location.href = `mailto:${captainEmail}?subject=${subject}&body=${body}`;
            },
            title: 'Request to Join Pool',
            modalButtonText: 'Email Captain',
            modalText: 'Email the captain of this pool with your request.'
        },
        {
            buttonText: 'Evaluate',
            action: () => {
                // Assuming captainInfo contains the email address of the captain
                const captainEmail = captainInfo ? captainInfo.email : "defaultEmail@example.com";
                const captainName = captainInfo ? captainInfo.first_name : "Captain";
                const subject = encodeURIComponent("Evaluation Period Request ");
                const body = encodeURIComponent("Dear " + captainName + ",\n\n[Request]\n\nBest regards,");
                window.location.href = `mailto:${captainEmail}?subject=${subject}&body=${body}`;
            },
            title: 'Pool is in Evaluation Period',
            modalButtonText: 'Email Captain',
            modalText: 'Click here to email the captain of this pool if you have a comment, question, or late request to join during the evaluation period.'
        }];
    const [buttonOptionsIndex, setButtonOptionsIndex] = useState(null);

    const handleCloseIframeModal = () => {
        setShowIframeModal(false);
    };


    useEffect(() => {
        const getPoolData = async () => {
            try {
                console.log("I am running again here1");
                console.log("ACCount STAGE: " + accountStage);
                setModalShow(false);
                setShowAddModal(false);

                setModalMessage(null);
                setButtonOptionsIndex(null);
                setSigningInterface(null);

                setIsBottomLoading(true);
                setShowIframeModal(false);
                const response = await myAxiosInstance.get('/api/pools/' + slug + '/');
                console.log("I am running again here2");

                const data = response.data;
                setUserInfo(data.user_info);
                setPublicDetails(data.public_details);
                setPrivateDetails(data.hasOwnProperty('private_details') ? data.private_details : null);
                setExtraPrivateDetails(data.hasOwnProperty('extra_private_details') ? data.extra_private_details : null);
                setCaptainInfo(data.hasOwnProperty('captain_info') ? data.captain_info : null);
                setOtherUsers(data.hasOwnProperty('other_users') ? data.other_users : []);
                setOtherNonUsers(data.hasOwnProperty('other_non_users') ? data.other_non_users : []);
                const poolKey = `pool_${slug}_first_time`;

                // Check if this is the first time visiting this pool
                if (!localStorage.getItem(poolKey) && data.user_info.status !== "not_associated") {
                    // Set local storage flag to prevent future triggers
                    localStorage.setItem(poolKey, 'true');

                    // Logic to set iframe source or perform actions
                    setShowIframeModal(true); // Show modal with iframe
                }

                let currentIndex = 6; // Updated logic using the local variable
                if (data.user_info.status === "invited_not_accepted") {
                    console.log("cr");
                    currentIndex = 0;
                    setShowTutorial(true);
                } else if (data.user_info.status === "invited_accepted") {
                    if (data.user_info.is_captain) {
                        currentIndex = 3;
                    } else {
                        currentIndex = 1;
                    }
                } else if (data.user_info.status === "accepted_not_signed") {
                    setShowTutorial(true);
                    if (accountStage == 3) {
                        if (data.private_details.evaluate) {
                            currentIndex = 7;
                        }
                        else {
                            currentIndex = 2;
                        }

                    }
                    else {
                        currentIndex = 5;
                    }

                } else if (data.user_info.status === "accepted_signed" || data.user_info.status === "member") {
                    currentIndex = 4;
                }

                setButtonOptionsIndex(currentIndex); // Setting the index after determination
                if (reload == 1 && location.state && location.state.actionButtonClicked) {
                    handleButtonClick(currentIndex);
                }
                console.log("I am running again here3");
                console.log(data);


            } catch (error) {
                console.error('Error fetching pool details', error.response.data);
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };

        getPoolData();
    }, [slug, reload]);

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [poolMembers]); // Scrolls to the bottom each time an invite is added


    const handleAddInvite = () => {
        poolMembers.current.push({
            company: '',
            email: '',
            first_name: '',
            graduation_date: '',
            is_verified: '',
            last_name: '',
            major_or_grad_program: '',
            pooled: '',
            portfolio_count: '',
            profile_picture: '',
            slug: '',
            university: ''
        });
        setTriggerRender(prev => prev + 1); // Force re-render
        //addSubComponentRef();
    }



    const handleDeleteUser = (index) => {
        poolMembers.current = poolMembers.current.filter((_, i) => i !== index);
        //subComponentRefs.current = subComponentRefs.current.filter((_, i) => i !== index);
        setTriggerRender(prev => prev + 1); // Force re-render
    };




    const editPoolMember = (selected, index) => {
        poolMembers.current[index] = {...poolMembers.current[index], ...selected}; // Directly update ref without triggering re-render
    };


    const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
    {
        setReload(reload + 1);
    };

    const handleCaptainClick = () => {
        if (captainInfo) {
            navigate('/dashboard/user/' + captainInfo.slug)
        }
    }

    const handleButtonClick = (tempIndex) => //change this
    {
        if (tempIndex == 2 || tempIndex == 4) {
            console.log("BUTTON LCIBK URL");
            //setSigningInterface("temp");
            getInterfaceURL();
        }
        console.log("boi" + buttonOptionsIndex);
        if (tempIndex == 2)
        {
            setShowRecap(true);
        }
        else{
            setModalShow(true);
        }
        
    }




    const handleClose = () => {
        setModalShow(false)
        setModalMessage(null);
        setManageReload(!manageReload);
    };


    const handleViewModalButtonClick = () => //change this
    {
        setModalShow(false);
    }

    const getInterfaceURL = async () => {
        let response = await myAxiosInstance.get('/api/get-document/' + slug + '/');
        console.log(response);
        setSigningInterface(response.data.document_link);
    }

    const signExternally = async () => {
        setModalMessage(<Loading />);
        let response = await myAxiosInstance.get('/api/get-document/' + slug + '/');
        const newWindow = window.open(response.data.document_link, '_blank', 'noopener,noreferrer');
        //const newWindow = window.open("https://app.prospinity.com/contract-not-live", '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        setModalMessage(null);

    }

    /////FIX REGISTRATION
    const apiPoolAction = async (endpoint, requestType, data, shouldReload) => {
        return apiP(data, endpoint + "/" + slug + "/", setModalMessage, "Done!", null, null, shouldReload, reload, setReload, requestType);
    }

    const handleManagePoolModalButtonClick = async () => {
        if (delPool) {
            const response = await apiPoolAction('update-delete-pools', "delete", null, false);
            if (response) {
                navigate('/dashboard/home');
            }

        }
        else { //determine order of this
            let final_users = editPoolData.invite_emails;
            if (removeInvitees.length != 0) {
                const removedUsers = removeInvitees.map(invitee => invitee.email);
                final_users = final_users.filter(item => !removedUsers.includes(item));
            }
            if (addInvitees.length != 0) {
                const addedUsers = addInvitees.map(invitee => invitee.email);
                final_users = [...final_users, ...addedUsers];
            }
            console.log("final users: " + final_users);
            const data = { ...editPoolData, invite_emails: [...final_users, ...inviteEmails] };
            const response = await apiPoolAction('update-delete-pools', "put", data, false);
            if (response) {
                setReload(reload + 1);
                navigate('/dashboard/pool/' + response.data.slug + "/");
            }
            if (response == null) {
                //setManageReload(!manageReload);
            }
        }

    }


    const isMdOrLarger = useMediaQuery({ minWidth: 768 });


    return (
        isBottomLoading ?
            <Loading />
            :
            <>
                {showIframeModal && 
                    <FullScreenYouTubePopup videoId={"VYDAj3I00MI"} onClose={handleCloseIframeModal}/>
                }
                
                <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
                    <div className='home-joxyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
                        {!isMdOrLarger && 
                            <div className='d-flex w-100 justify-content-between align-items-center mt-3'>
                            <div>
                            <StyledH5><strong>Pool Highlights</strong></StyledH5>
                            </div>
                            <div>
                            {buttonOptionsIndex !== null && (
                                <StyledButton onClick={() => handleButtonClick(buttonOptionsIndex)} style={{borderRadius: '1.5rem', fontSize: '0.8rem'}} className='px-4'>
                                    {buttonOptions[buttonOptionsIndex].buttonText}
                                </StyledButton>
                            )}
                            </div>
                            </div>
                        }
                        <StyledCard className='mt-3'>
                            <StyledCard.Body>
                                <div className='d-flex w-100 justify-content-between align-items-center' style={{marginTop: '-0.5rem'}}>
                                    <div className='d-flex align-items-center' style={{maxWidth: isMdOrLarger ? '90%' : '100%'}}>
                                        <img
                                            src={'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
                                            alt={"Preview"}
                                            onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                                            style={profilePictureStyle}
                                        />
                                        <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, maxWidth: '100%' }}>
                                            <div style={{
                                                cursor: 'pointer',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize: '1.8rem',
                                                minWidth: 0
                                            }}>
                                                <strong style={{
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    textDecoration: 'none',
                                                    color: 'inherit',
                                                }}
                                                    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                                                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}

                                                >{privateDetails ? privateDetails.name : stylePoolSlug(publicDetails.slug.toUpperCase())}</strong>
                                            </div>
                                            <div style={{
                                                cursor: 'pointer',
                                                minWidth: 0,
                                                marginTop: '-0.3rem'
                                            }}>
                                                <span style={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'none',
                                                    color: 'inherit',
                                                }}
                                                    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                                                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}

                                                >Captain: {captainInfo ? captainInfo.first_name + " " + captainInfo.last_name : "Private"}</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
                                                <div
                                                    style={{
                                                        width: '0.5rem',
                                                        height: '0.5rem',
                                                        backgroundColor: pastDeadline(publicDetails.sign_deadline) ? 'green' : 'orange',
                                                        borderRadius: '50%',
                                                        marginRight: '0.2rem',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        minWidth: 0,
                                                        fontSize: '0.8rem',
                                                    }}
                                                >

                                                    {/*pastDeadline(data.sign_deadline) ? <strong>Status: </strong> : pastDeadline(data.invitation_deadline) ? <strong>Sign Deadline: </strong> : <strong>Invitation Deadline: </strong>*/}
                                                    <PoolDeadlineTimer invitationDeadline={publicDetails.invitation_deadline} signDeadline={publicDetails.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>                  </div>
                                            </div>

                                        </div>
                                    </div>
                                    {isMdOrLarger && 
                                    <div style={{marginTop: "0.7rem"}}>
                                        {(userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" || userInfo.status === "accepted_signed" || userInfo.status === "accepted_not_signed" ) && <StyledButton className='mr-2'   onClick={handlePoolAddButtonClick}>{"Add Members"}</StyledButton>}
                                        {buttonOptionsIndex !== null && (
                                            <StyledButton onClick={() => handleButtonClick(buttonOptionsIndex)} className='px-4'>
                                                {buttonOptions[buttonOptionsIndex].buttonText}
                                            </StyledButton>
                                        )}
                                    </div>}
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                        {isMdOrLarger && 
                        <StyledH5 className='mt-4'><strong>Pool Highlights</strong></StyledH5>
                        }
                        <StyledCard className='mt-2'>
                            <StyledCard.Body>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'  style={{ flex: '1 1 0' }}>
                                    <div><StyledH5><strong>{publicDetails.member_count}</strong></StyledH5></div>
                                    <div style={{fontSize: '0.8rem', marginTop: '-0.4rem'}}>Members</div>
                                    </div>
                                    <div className='d-flex flex-column justify-content-center align-items-center'  style={{ flex: '1 1 0' }}>
                                        <div><StyledH5><strong>{publicDetails.contribution_percent}%</strong></StyledH5></div>
                                        <div style={{fontSize: '0.8rem', marginTop: '-0.4rem'}}>Future Earnings</div>
                                    </div>
                                    <div className='d-flex flex-column justify-content-center align-items-center'  style={{ flex: '1 1 0' }}>
                                        <div><StyledH5><strong>{publicDetails.time_horizon} years</strong></StyledH5></div>
                                        <div style={{fontSize: '0.8rem', marginTop: '-0.4rem'}}>Time Horizon</div>
                                    </div>
                                    {/*extraPrivateDetails == null || privateDetails.evaluate ? <></> :
                                                <Col className='d-flex flex-column justify-content-center align-items-center' xs={3}>
                                                    <strong>Activation</strong>
                                                    <div>{extraPrivateDetails.percent_signitures_to_activate}%</div>
                                    </Col>*/}
                                </div>
                            </StyledCard.Body>
                        </StyledCard>
                        {isMdOrLarger ? <StyledH5 className='mt-4 mb-2'><strong>Members</strong></StyledH5> :
                                <div className='d-flex w-100 justify-content-between align-items-center mt-1'>
                                    <div>
                                    <StyledH5 className='mt-3 mb-4'><strong>Members</strong></StyledH5>
                                    </div>
                                    {(userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" || userInfo.status === "accepted_signed" || userInfo.status === "accepted_not_signed" ) && 
                                        <div>
                                            <StyledButton style={{borderRadius: '1.5rem', fontSize: '0.8rem'}} className='px-4 mb-1' onClick={handlePoolAddButtonClick}>{"Add Members"}</StyledButton>
                                        </div>
                                    }
                                </div>
                        }
                        {
                            userInfo.status === 'not_associated' ? <StyledCard><StyledCard.Body><div className='w-100 d-flex justify-content-center align-items-center' style={{minHeight: isMdOrLarger ? '40vh' : '15vh'}}><div style={{fontSize: '1.2rem'}}>The captain has made this pool <strong>private</strong>.</div></div></StyledCard.Body></StyledCard> : privateDetails.evaluate ? <StyledCard><StyledCard.Body><>This pool is now locked in the evaluation period. Prospinity will either move the pool forward to the Signing Period, or assign you to a new pool later. You will be notified when a decision is made. Stay tuned!</></StyledCard.Body></StyledCard> :
                                <StyledCard style={{minHeight: '40vh'}}>
                                    <StyledCard.Body className='px-4'>
                                        
                                            {
                                                userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" ?
                                                    userInfo.is_captain ?
                                                        <>
                                                            
                                                            
                                                            <div style={{ textAlign: 'center' }} className='w-100 mb-1'>Accepted Invite</div>
                                                            
                                                                <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                                    
                                                                    {otherUsers.filter(otherUserInfo => otherUserInfo.has_accepted_invite === true).map((otherUserInfo, index) => (
                                                                        <div className='my-1 mx-1'>
                                                                            <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            
                                                            
                                                            <div style={{ textAlign: 'center' }} className='w-100 mb-1'>Waiting</div>
                                                                <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                                    
                                                                    {otherUsers.filter(otherUserInfo => otherUserInfo.has_accepted_invite === false).map((otherUserInfo, index) => (
                                                                        <div className='my-1 mx-1'>
                                                                            <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            
                                                        </>
                                                        :
                                                        <>
                                                        <div style={{ textAlign: 'center' }} className='w-100 mb-1'>Invitees</div>
                                                            <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                                
                                                                {otherUsers.map((otherUserInfo, index) => (
                                                                    <div className='my-1 mx-1'>
                                                                        <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    :
                                                    
                                                        <div className='w-100 d-flex flex-wrap justify-content-center'>

                                                            {otherUsers.map((otherUserInfo, index) => (
                                                                <div className='my-1 mx-1'>
                                                                    <UserPreviewCard preview_object={otherUserInfo}></UserPreviewCard>
                                                                </div>
                                                            ))}
                                                            {otherNonUsers.map((otherUserInfo, index) => (
                                                                <div className='my-1 mx-1'>
                                                                    <UserPreviewCard preview_object={otherUserInfo} nonUser={true}></UserPreviewCard>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    
                                            }

                                        
                                    </StyledCard.Body>


                                </StyledCard>}
                    </div>
                </div >


                {showAddModal == true && 
                    
                    <CustomModal
                        show={showAddModal}
                        setShow={setShowAddModal}
                        title={"Add people to pool"}
                        message={""}
                        setMessage={setShowAddModal}
                        actionText="Close"
                        actionFunction={handleCloseAddButton}
                        handleClose={handleCloseAddButton}
                        showCloseButton={false}
                    >
                        <StyledParagraph>After you join your pool and it activates (in  <PoolDeadlineTimer invitationDeadline={publicDetails.invitation_deadline} signDeadline={publicDetails.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>), you will be able to propose new members, vote them in, and start growing your success pool. 
 
                        You will also be able to change your pool name.</StyledParagraph>
                    </CustomModal>

                }


                {buttonOptionsIndex != null ?
                    <CustomModal show={modalShow}
                        setShow={setModalShow}
                        title={buttonOptions[buttonOptionsIndex].title}
                        actionText={buttonOptions[buttonOptionsIndex].modalButtonText}
                        message={modalMessage}
                        setMessage={setModalMessage}
                        actionFunction={buttonOptions[buttonOptionsIndex].action}
                        handleClose={handleClose}
                    >
                        {
                            buttonOptionsIndex === 0 ?
                                (
                                    <>
                                        By accepting this invite to view sections of our platform, you agree to keep all content,
                                        including the Success Pooling Agreement, confidential and acknowledge that it is the
                                        intellectual property of Prospinity. Accepting this invite does not constitute a binding legal
                                        commitment to any terms other than the confidentiality and intellectual property
                                        acknowledgment itself. For full details, see our <a href="/agreement-confidentiality">Confidentiality and Intellectual Property Agreement</a>.
                                    </>
                                ) :
                                buttonOptions[buttonOptionsIndex].modalText
                        }
                        {
                            buttonOptionsIndex == 3 ?

                                <ManagePool
                                    editPoolData={editPoolData}
                                    setEditPoolData={setEditPoolData}
                                    setModalMessage={setModalMessage}
                                    slug={slug}
                                    setDelPool={setDelPool}
                                    delPool={delPool}
                                    removeInvitees={removeInvitees}
                                    setRemoveInvitees={setRemoveInvitees}
                                    addInvitees={addInvitees}
                                    setAddInvitees={setAddInvitees}
                                    manageReload={manageReload}
                                    inviteEmails={inviteEmails}
                                    setInviteEmails={setInviteEmails}
                                ></ManagePool>

                                : <></>}

                        {buttonOptionsIndex == 2 || buttonOptionsIndex == 4 ? signingInterface ? (
                            <div style={{ height: '80vh', width: '100%' }}>
                                <iframe src={signingInterface} style={{ border: 'none', width: '100%', height: '100%' }} title="Signing Interface"></iframe>
                            </div>
                        ) : <Loading /> : <></>}
                    </CustomModal>
                    :
                    <></>
                }
                
                
                <SigningRecap
                    show={showRecap}
                    tutorialPart={recapPart}
                    onNext={handleNextRecap}
                    onSkip={handleSkipRecap}
                    onBack={handleBackRecap}
                    poolDetails={{name: privateDetails.name, contribution_percent: publicDetails.contribution_percent, time_horizon: publicDetails.time_horizon, member_count: publicDetails.member_count, captain_first_name: captainInfo.first_name, captain_last_name: captainInfo.last_name}}
                />
            </>

    );
}

export default PoolPage;