import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function BonusRedirect() {
    // Get the club name from the URL
    const { club_name } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (club_name === "pike-berkeley") {
            // Generate a random number between 1 and 5
            const randomOrd = Math.floor(Math.random() * 5) + 1;

            // Generate the URL
            const targetLink = `https://o0bf65aidiq.typeform.com/to/X0wUoz7H#ord=${randomOrd}`;

            // Redirect to the generated URL
            window.location.href = targetLink;
        } else if (club_name === "brb") {
            // Generate a random number between 1 and 5
            const randomOrd = Math.floor(Math.random() * 5) + 1;

            // Generate the URL
            const targetLink = `https://o0bf65aidiq.typeform.com/to/lCGPbGpX#ord=${randomOrd}`;

            // Redirect to the generated URL
            window.location.href = targetLink;
        } else {
            // Navigate to the home/dashboard if club_name is not "tamid"
            navigate('/dashboard'); // Change '/dashboard' to '/home' if you want to navigate to home
        }
    }, [club_name, navigate]);


    return null;
}

export default BonusRedirect;
