import React, { useState, useEffect, useRef } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { useParams, useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

import { capitalizeClubName, SurveyButton, SurveyNavigationButtons, SurveyParagraph, SecondaryParagraph, SurveyInput, SurveyOptionContainer, SurveyOptionButton, SelectionContainer, ListContainer, ListItem, Label, CheckboxContainer, SurveyError, FileUpload } from './SurveyComponents';
import { NullComponent } from 'stream-chat-react';


// Survey Pages
export function IntroPage() {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const { club_name } = useParams();

    return (
        <>
            {/* Text sections with responsive font sizes */}
            <SurveyParagraph isMdOrLarger={isMdOrLarger}>
                <strong>Welcome to Prospinity &lt;&gt; {capitalizeClubName(club_name)} Partnership!</strong>
            </SurveyParagraph>

            <SurveyParagraph isMdOrLarger={isMdOrLarger}>
                You probably want to click through this as fast as possible to get {capitalizeClubName(club_name)} money. We would too if we were in your shoes.
            </SurveyParagraph>

            <SurveyParagraph isMdOrLarger={isMdOrLarger}>
                But we recommend you take your time for this. Our bet is that you haven't heard of anything like this before.
            </SurveyParagraph>

            <SurveyParagraph isMdOrLarger={isMdOrLarger}>
                <strong>Only valid responses,</strong> checked by an algorithm for time spent, will count for the partnership. Read everything carefully.
            </SurveyParagraph>
        </>
    );
}

export function SimpleTextPage({ paragraphs, typing, typingSpeed, onTypingDone }) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);

    useEffect(() => {
        if (isTypingDone || !typing) {
            onTypingDone(); // Notify parent when typing is done
        }
    }, [isTypingDone, onTypingDone]);

    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };

    return(
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                    pIndex <= paragraphIndex ? (
                        <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                            {paragraph.sequences.map((sequence, sIndex) =>
                                pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                    <TypeAnimation
                                        key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                        sequence={[
                                            sequence.content,
                                            () => handleTypingComplete(), // Trigger next sequence or paragraph
                                        ]}
                                        wrapper={sequence.wrapper}
                                        cursor={false}
                                        speed={typingSpeed}
                                        repeat={0}
                                        style={sequence.style}
                                    />
                                ) : null)
                            }
                        </SurveyParagraph>
                    ) : null
            )}
        </>       
    )
}

export function SimpleInputTextField({ paragraphs, typing, typingSpeed, onTypingDone, inputRef, handleInput, inputError, secondaryText="" }) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);

    useEffect(() => {
        if (isTypingDone || !typing) {
            onTypingDone(); // Notify parent when typing is done
        }
    }, [isTypingDone, onTypingDone]);

    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };
    

    return(
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                    pIndex <= paragraphIndex ? (
                        <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                            {paragraph.sequences.map((sequence, sIndex) =>
                                pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                    <TypeAnimation
                                        key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                        sequence={[
                                            sequence.content,
                                            () => handleTypingComplete(), // Trigger next sequence or paragraph
                                        ]}
                                        wrapper={sequence.wrapper}
                                        cursor={false}
                                        speed={typingSpeed}
                                        repeat={0}
                                        style={sequence.style}
                                    />
                                ) : null
                            )}
                        </SurveyParagraph>
                    ) : null
            )}

            {/* oPTION Field */}
            {isTypingDone && 
                <>
                    {secondaryText !== "" && 
                        <SecondaryParagraph>
                            {secondaryText}
                        </SecondaryParagraph>
                    }
                    <SurveyInput
                        type="text"
                        placeholder={"Type your answer here..."} // Customizable placeholder
                        defaultValue={inputRef.current} // Browser manages this value
                        autoFocus={true} // Automatically focus the input by default
                        isMdOrLarger={isMdOrLarger}
                        onChange={(e) => handleInput(e.target.value)} // Update parent state on change
                    />

                    {/* Error message */}
                    {inputError && (
                        <SurveyError>
                            Please answer the question to continue.
                        </SurveyError>
                    )}
                </>
            }
        </>
            
    )
}

export function SimpleChoicePage({ paragraphs, options, typing, typingSpeed, onTypingDone, choiceRef, handleChoice, choiceError, secondaryText="" }) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);

    useEffect(() => {
        if (isTypingDone || !typing) {
            onTypingDone(); // Notify parent when typing is done
        }
    }, [isTypingDone, onTypingDone]);

    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };
    

    return(
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                    pIndex <= paragraphIndex ? (
                        <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                            {paragraph.sequences.map((sequence, sIndex) =>
                                pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                    <TypeAnimation
                                        key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                        sequence={[
                                            sequence.content,
                                            () => handleTypingComplete(), // Trigger next sequence or paragraph
                                        ]}
                                        wrapper={sequence.wrapper}
                                        cursor={false}
                                        speed={typingSpeed}
                                        repeat={0}
                                        style={sequence.style}
                                    />
                                ) : null
                            )}
                        </SurveyParagraph>
                    ) : null
            )}

            {/* oPTION Field */}
            {isTypingDone && 
                <>
                    {secondaryText !== "" && 
                        <SecondaryParagraph>
                            {secondaryText}
                        </SecondaryParagraph>
                    }
                    <SurveyOptionContainer>
                        {Object.entries(options).map(([key, option]) => (
                            <SurveyOptionButton
                                key={key}
                                isSelected={choiceRef.current === key}
                                onClick={() => handleChoice(key)}
                                isMdOrLarger={isMdOrLarger}
                            >
                                <span>{key}</span> {/* Display A, B, C, etc. */}
                                {option}
                            </SurveyOptionButton>
                        ))}
                    </SurveyOptionContainer>
                    {/* Error message */}
                    {choiceError && (
                        <SurveyError>
                            Please select one of the above to continue.
                        </SurveyError>
                    )}
                </>
            }
        </>
            
    )
}

export function TextWithImage({ paragraphs, typing, typingSpeed, onTypingDone, image, secondaryText="" }) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);
    const imageRef = useRef(null); // Ref for scrolling to the image

    useEffect(() => {
        // Define an async function to use await
        const handleTypingDone = async () => {
            if (isTypingDone || !typing) {
                // Scroll to the image once it appears
                if (imageRef.current) {
                    imageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                }

                // Wait for half a second
                await new Promise((resolve) => setTimeout(resolve, 500));

                // Notify parent when typing is done
                onTypingDone();
            }
        };

        handleTypingDone(); // Call the async function

    }, [isTypingDone, onTypingDone]);


    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };

    return(
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                    pIndex <= paragraphIndex ? (
                        <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                            {paragraph.sequences.map((sequence, sIndex) =>
                                pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                    <TypeAnimation
                                        key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                        sequence={[
                                            sequence.content,
                                            () => handleTypingComplete(), // Trigger next sequence or paragraph
                                        ]}
                                        wrapper={sequence.wrapper}
                                        cursor={false}
                                        speed={typingSpeed}
                                        repeat={0}
                                        style={sequence.style}
                                    />
                                ) : null)
                            }
                        </SurveyParagraph>
                    ) : null
            )}

            {/* Picture */}
            {isTypingDone && 
                <>
                    <motion.div
                        ref={imageRef} // Attach the ref to enable scrolling
                        initial={{ opacity: 0, y: 50 }} // Start invisible and slightly below
                        animate={{ opacity: 1, y: 0 }} // Fade in and move up
                        transition={{ duration: 0.6 }} // Animation duration
                        style={{ marginTop: "2rem", textAlign: "center" }}
                    >
                        <img
                            src={image} // Replace with the actual image import
                            alt="Success Pools Graphic"
                            style={{
                                width: "100%",
                                borderRadius: "8px", // Optional styling
                            }}
                        />
                    </motion.div>
                </>
            }
        </>       
    )
}

export function TextWithSideImage({ paragraphs, typing, typingSpeed, onTypingDone, image, secondaryText="" }) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);
    const imageRef = useRef(null); // Ref for scrolling to the image

    useEffect(() => {
        // Define an async function to use await
        const handleTypingDone = async () => {
            if (isTypingDone || !typing) {
                // Scroll to the image once it appears
                if (imageRef.current) {
                    imageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                }

                // Wait for half a second
                await new Promise((resolve) => setTimeout(resolve, 500));

                // Notify parent when typing is done
                onTypingDone();
            }
        };

        handleTypingDone(); // Call the async function

    }, [isTypingDone, onTypingDone]);


    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };

    return(
        !isMdOrLarger ?
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                pIndex <= paragraphIndex ? (
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                        {paragraph.sequences.map((sequence, sIndex) =>
                            pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                <TypeAnimation
                                    key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                    sequence={[
                                        sequence.content,
                                        () => handleTypingComplete(), // Trigger next sequence or paragraph
                                    ]}
                                    wrapper={sequence.wrapper}
                                    cursor={false}
                                    speed={typingSpeed}
                                    repeat={0}
                                    style={sequence.style}
                                />
                            ) : null
                        )}
                    </SurveyParagraph>
                ) : null
            )}

            {/* Picture */}
            {isTypingDone && 
                <>
                    {secondaryText !== "" && 
                        <SecondaryParagraph>
                            {secondaryText}
                        </SecondaryParagraph>
                    }

                    <motion.div
                        ref={imageRef} // Attach the ref to enable scrolling
                        initial={{ opacity: 0, y: 50 }} // Start invisible and slightly below
                        animate={{ opacity: 1, y: 0 }} // Fade in and move up
                        transition={{ duration: 0.6 }} // Animation duration
                        style={{ marginTop: "2rem", textAlign: "center" }}
                    >
                        <img
                            src={image} // Replace with the actual image import
                            alt="Success Pools Graphic"
                            style={{
                                maxWidth: isMdOrLarger ? "70%" : "100%", // Adjust width based on screen size
                                borderRadius: "8px", // Optional styling
                            }}
                        />
                    </motion.div>
                </>
            }
        </>
        :
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    maxWidth: "100%", // Restrict content width for symmetry
                    marginTop: "-3rem",
                }}
            >
                {/* Text Section */}
                <motion.div
                    initial={{ opacity: 0, x: -80 }} // Slide in from the left
                    animate={{ opacity: 1, x: 0 }} // Fade in and move to position
                    exit={{ opacity: 0, x: -80, y: -50 }} // Exit animation, slide up and left smoothly
                    transition={{ duration: 0.8 }} // Animation duration
                    style={{
                        textAlign: "left",
                        transform: "translateX(-5rem)", // Simulate margin-left: 3rem
                    }}
                >
                    {paragraphs.map((paragraph, pIndex) =>
                        !typing ? 
                            <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                        : 
                        pIndex <= paragraphIndex ? (
                            <SurveyParagraph
                                isMdOrLarger={isMdOrLarger}
                                key={`paragraph-${pIndex}`}
                                style={{transform: "translateX(-5rem)", width: "140%"}}
                            >
                                {paragraph.sequences.map((sequence, sIndex) =>
                                    pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                        <TypeAnimation
                                            key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                            sequence={[
                                                sequence.content,
                                                () => handleTypingComplete(), // Trigger next sequence or paragraph
                                            ]}
                                            wrapper={sequence.wrapper}
                                            cursor={false}
                                            speed={typingSpeed}
                                            repeat={0}
                                            style={sequence.style}
                                        />
                                    ) : null
                                )}
                            </SurveyParagraph>
                        ) : null
                    )}

                    {isTypingDone && secondaryText !== "" && (
                        <SecondaryParagraph>
                            {secondaryText}
                        </SecondaryParagraph>
                    )}
                </motion.div>

                {/* Image Section */}
                <motion.div
                    ref={imageRef}
                    initial={{ opacity: 0, x: 300 }} // Slide in from the right
                    animate={{ opacity: 1, x: 0 }} // Fade in and move to position
                    exit={{ opacity: 0, x: 300, y: -50 }} // Exit animation, slide up and right smoothly
                    transition={{ duration: 0.8, delay: 0.2 }} // Animation duration with slight delay
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        transform: "translateX(15rem)", // Simulate margin-right: 3rem
                    }}
                >
                    <img
                        src={image} // Replace with the actual image import
                        alt="Success Pools Graphic"
                        style={{
                            width: "40vw",
                            height: "auto",
                            borderRadius: "12px", // Optional styling for a premium look
                            transform: "translateX(12rem)"
                        }}
                    />
                </motion.div>
            </div>
        </>
    )
}

export function MultiSelectPage({ paragraphs, options, typing, typingSpeed, onTypingDone, selectionRef, handleSelect, selectError, secondaryText=""}) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);
    const [selectedPeopleState, setSelectedPeopleState] = useState(selectionRef.current);

    const handleToggle = (person) => {
        if (selectedPeopleState.includes(person)) {
            setSelectedPeopleState(selectedPeopleState.filter((p) => p !== person));
        } else {
            setSelectedPeopleState([...selectedPeopleState, person]);
        }
      };

    useEffect(() => {
        if (isTypingDone || !typing) {
            onTypingDone(); // Notify parent when typing is done
        }
    }, [isTypingDone, onTypingDone]);

    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };
    

    return(
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                    pIndex <= paragraphIndex ? (
                        <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                            {paragraph.sequences.map((sequence, sIndex) =>
                                pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                    <TypeAnimation
                                        key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                        sequence={[
                                            sequence.content,
                                            () => handleTypingComplete(), // Trigger next sequence or paragraph
                                        ]}
                                        wrapper={sequence.wrapper}
                                        cursor={false}
                                        speed={typingSpeed}
                                        repeat={0}
                                        style={sequence.style}
                                    />
                                ) : null
                            )}
                        </SurveyParagraph>
                    ) : null
            )}

            {/* oPTION Field */}
            {isTypingDone && 
                <>
                    <SelectionContainer>
                        <ListContainer>
                        {options.map((person) => (
                            <ListItem isMdOrLarger={isMdOrLarger} key={person} onClick={() => {handleSelect(person); handleToggle(person);}}>
                                <Label isMdOrLarger={isMdOrLarger}>{person}</Label>
                                <CheckboxContainer isMdOrLarger={isMdOrLarger} className={selectedPeopleState.includes(person) ? "checked" : ""}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M20.285,6.071c-0.391-0.391-1.023-0.391-1.414,0L9,15.943l-3.871-3.871c-0.391-0.391-1.023-0.391-1.414,0 s-0.391,1.023,0,1.414l4.578,4.578c0.391,0.391,1.023,0.391,1.414,0L20.285,7.485C20.676,7.094,20.676,6.462,20.285,6.071z" />
                                    </svg>
                                </CheckboxContainer>
                            </ListItem>
                        ))}
                        </ListContainer>
                    </SelectionContainer>

                    {/* Error message */}
                    {selectError && (
                        <SurveyError>
                            Please select 10 people to continue.
                        </SurveyError>
                    )}
                </>
            }
        </>
    )
}

export function FileUploadPage({ paragraphs, typing, typingSpeed, onTypingDone, fileRef, handeFile, secondaryText="" }) {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    const [paragraphIndex, setParagraphIndex] = useState(0);
    const [sequenceIndex, setSequenceIndex] = useState(0);
    const [isTypingDone, setIsTypingDone] = useState(!typing);

    useEffect(() => {
        if (isTypingDone || !typing) {
            onTypingDone(); // Notify parent when typing is done
        }
    }, [isTypingDone, onTypingDone]);

    const handleTypingComplete = () => {
        const currentParagraph = paragraphs[paragraphIndex];
        if (sequenceIndex < currentParagraph.sequences.length - 1) {
            setSequenceIndex(sequenceIndex + 1);
        } else if (paragraphIndex < paragraphs.length - 1) {
            setParagraphIndex(paragraphIndex + 1);
            setSequenceIndex(0); // Reset sequence index for the next paragraph
        } else {
            setIsTypingDone(true); // Mark typing as done when all paragraphs are completed
        }
    };
    

    return(
        <>
            {paragraphs.map((paragraph, pIndex) =>
                !typing ? 
                    <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>{paragraph}</SurveyParagraph>
                : 
                    pIndex <= paragraphIndex ? (
                        <SurveyParagraph isMdOrLarger={isMdOrLarger} key={`paragraph-${pIndex}`}>
                            {paragraph.sequences.map((sequence, sIndex) =>
                                pIndex < paragraphIndex || (pIndex === paragraphIndex && sIndex <= sequenceIndex) ? (
                                    <TypeAnimation
                                        key={`paragraph-${pIndex}-sequence-${sIndex}`}
                                        sequence={[
                                            sequence.content,
                                            () => handleTypingComplete(), // Trigger next sequence or paragraph
                                        ]}
                                        wrapper={sequence.wrapper}
                                        cursor={false}
                                        speed={typingSpeed}
                                        repeat={0}
                                        style={sequence.style}
                                    />
                                ) : null
                            )}
                        </SurveyParagraph>
                    ) : null
            )}

            {/* oPTION Field */}
            {isTypingDone && 
                <>
                    {secondaryText !== "" && 
                        <SecondaryParagraph>
                            {secondaryText}
                        </SecondaryParagraph>
                    }
                    
                    <FileUpload 
                        onFileUpload={(file) => handeFile(file)} 
                        onFileRemove={() => handeFile(null)}
                        errorMessage=""
                        currentFile={fileRef.current}
                    />

                </>
            }
        </>
            
    )
}

export function EndPage() {
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isMdOrLarger ? "40vh" : "70vh", // Vertically center using full viewport height
                backgroundColor: "#02002B", // Optional, matches your other styles
            }}
        >
            <div
                style={{
                    maxWidth: "600px", // Adjust this to control width
                    width: isMdOrLarger ? "90%" : "100%", // Fallback for smaller screens
                    textAlign: "center", // Center text horizontally
                }}
            >
                {/* Text sections with responsive font sizes */}
                <SurveyParagraph isMdOrLarger={isMdOrLarger} style={{textAlign: "center"}}>
                    {isMdOrLarger ? (
                        <h1>Click below to create a Prospinity account.</h1>
                    ) : (
                        <h3>Click below to create a Prospinity account.</h3>
                    )}
                </SurveyParagraph>

                <SurveyParagraph isMdOrLarger={isMdOrLarger} style={{textAlign: "center"}}>
                    {isMdOrLarger ? (
                        <h3>
                            It takes two minutes and you will be able to immediately redeem your $10 partnership bonus on the platform.
                        </h3>
                    ) : (
                        <h4>
                            It takes two minutes and you will be able to immediately redeem your $10 partnership bonus on the platform.
                        </h4>
                    )}
                </SurveyParagraph>
            </div>
        </div>
    );
}